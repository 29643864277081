import * as $ from 'jquery';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import { loadStripe } from "@stripe/stripe-js";

const firebaseConfig = {
    apiKey: "AIzaSyBE4jvTTaRNKN0iXNd_B_AP4Xrwl4EXqYs",
    authDomain: "dedupeone.firebaseapp.com",
    databaseURL: "https://dedupeone-default-rtdb.firebaseio.com",
    projectId: "dedupeone",
    storageBucket: "dedupeone.appspot.com",
    messagingSenderId: "939637555230",
    appId: "1:939637555230:web:615611b6fa04962e56ae0f",
    measurementId: "G-JDNL35CYD1",
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  export const database = firebase.database();
  export const firestore = firebase.firestore();

  

  
export async function login() {
 

    const redirectUrl = "https://localhost:3000/commands.html";
  
  
   //live
 //  var url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=87aa90c7-4eaa-4987-9fa5-1572ad928bbf&response_type=id_token&redirect_uri=https://iconsforpowerpoint.com/commands.html&scope=user.read+openid+profile+email&response_mode=fragment&state=12345&nonce=678910";
   //local
  var url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=8fbff23b-5482-43a8-91db-23df1d33beb9&response_type=id_token&redirect_uri=https://qrcodeforoutlook.com/commands.html&scope=user.read+openid+profile+email&response_mode=fragment&state=12345&nonce=678910";

  let loginDialog;
  function processMessage(arg) {
    loginDialog.close();
    let id_token = arg.message;
    var data = parseJwt(id_token);
    var data1 = JSON.parse(data);
    window.localStorage.setItem('userEmail', data1.email);
    window.localStorage.setItem('userName', data1.name);
    if (id_token !== "") {
      window.localStorage.setItem('access_token', id_token);
      return data1; // Return the data1 value
    } else {
      throw new Error("No id_token received"); // Throw an error if id_token is empty
    }
  }

  return new Promise((resolve, reject) => {
    Office.context.ui.displayDialogAsync(url, { height: 60, width: 40 }, function (result) {
      if (result.error) {
        reject(result.error);
      } else {
        loginDialog = result.value;
        loginDialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogMessageReceived, function (arg) {
          try {
            const data1 = processMessage(arg);
            resolve(data1); // Resolve the promise with data1 value
          } catch (error) {
            reject(error); // Reject the promise if an error occurs
          }
        });
      }
    });
  });
}



function todayDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
        dd = "0" + dd;
    }

    if (mm < 10) {
        mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);
    return today;
}

// Check if the subscription date is valid
function dateCheck(subscription_ending_date) {
    var today = todayDate();
    var today_date = new Date(today);
    var subscription_end_date = new Date(subscription_ending_date);
    return subscription_end_date.getTime() > today_date.getTime();
}







// Function to check the subscription
export async function checkSubscription(value) {
    return new Promise(function (resolve) {
        // Get the email from localStorage
        var email =  window.localStorage.getItem("userEmail");
        var emailSubscriptionExist;
        var subscriptionNotExpired;

        let collectionRef = firebase.firestore().collection("payments").doc("qrCode");
        collectionRef.get().then(function (doc) {
            if (doc.exists) {
                let data = doc.data();
                data = data.records;


                var userData = data.filter(element => element.email === email);
                if (userData.length != 0) {
                    console.log(userData[0].date);
                
let tries=value;
                    let tokensLeft = userData[0].tokens;
                  
                    if (userData[0].free) {
                      if(userData[0].tokens===0) {
                        window.localStorage.setItem("tokens",userData[0].tokens);
                        subscriptionNotExpired = false;
                          emailSubscriptionExist = true;
  
  
                      }
                      else{
                        window.localStorage.setItem("tokens",userData[0].tokens);
                       emailSubscriptionExist = true;
                      subscriptionNotExpired = true;
                      }
                        //free trial ended purchase now

                    }
                    else {
                      window.localStorage.setItem("tokens","Unlimited");
                      subscriptionNotExpired = dateCheck(userData[0].date);
                        emailSubscriptionExist = true;


                    }

                    resolve(emailSubscriptionExist && subscriptionNotExpired);



                }



                else {
                    let currentDate = new Date();
                    currentDate.setDate(currentDate.getDate() + 30);
                    window.localStorage.setItem("tokens",5);
                    let tokensLeft = 7;
                    var newData = {
                        email: email,
                        tokens: 5,
                        date: currentDate.toString(),
                        free:true
                    };

                    collectionRef.update({
                        records: firebase.firestore.FieldValue.arrayUnion(newData)
                    }).then(function () {
                        emailSubscriptionExist = true;
                        subscriptionNotExpired = true;

                        resolve(emailSubscriptionExist && subscriptionNotExpired);



                    })
                }


            }


        });
    });
}




export async function pay(url, tokens) {
    return new Promise((resolve, reject) => {
      let paymentDialog;
  
      function PayprocessMessage(arg) {
        if (arg.error === 12006) {
          getAllTransactions().then((res) => {
            let currentDateInSeconds = new Date();
            currentDateInSeconds = currentDateInSeconds.toLocaleDateString();
            console.log(currentDateInSeconds);
            let email = window.localStorage.getItem('userEmail');
            var recordFound = res.filter((ele) => {
              if (ele.billing_details.email === email) {
                const date = new Date(ele.created * 1000);
                const dateString = date.toLocaleDateString();
                var createdDate = dateString;
                return createdDate === currentDateInSeconds;
              }
            });
  
            console.log(recordFound);
            if (recordFound.length != 0) {
              updateDb('purchase', tokens).then((res) => {
                // Handle success
                resolve(true);
              });
            } else {
              // Handle failure
              resolve(false);
            }
          });
        }
      }
  
      Office.context.ui.displayDialogAsync(url, { height: 60, width: 40 }, function (result) {
        if (result.error) {
          reject(result.error);
        } else {
          paymentDialog = result.value;
          paymentDialog.addEventHandler(
            Microsoft.Office.WebExtension.EventType.DialogEventReceived,
            PayprocessMessage
          );
        }
      });
    });
  }
  

async function getAllTransactions() {
    //const stripePromise = loadStripe('YOUR_STRIPE_PUBLISHABLE_KEY');

    const secretApiKey = 'sk_test_51MzyFGHLpJrNg9V0nw6laBpJYmQ2vGHEPzNCAcZTJLuycwQC5UhSnOkzgD5fmbyKvBGL9tgczWsM5lJo9qdHffLp00Lf7XqbEZ';
    const endpoint = 'https://api.stripe.com/v1/charges';

    const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + secretApiKey,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to retrieve transactions');
    }

    const data = await response.json();
    return data.data;
}


async function updateDb(value,tokens) {
    return new Promise(function (resolve) {
        // Get the email from localStorage
        let collectionRef = firebase.firestore().collection("payments").doc("qrCode");
        collectionRef.get().then(function (doc) {
            if (doc.exists) {
                let data = doc.data().records;
                let email= window.localStorage.getItem("userEmail");
                var index = data.findIndex(function (record) {
                    return record.email === email;
                });

                if (index !== -1) {
                    var updatedRecord;
                  
                     if (value == "purchase") {
                        let currentDate = new Date();
                        currentDate.setDate(currentDate.getDate() + 30);
                        let tokensLeft = tokens;
                        window.localStorage.setItem("tokens","Unlimited");
                        updatedRecord = {
                            email: data[index].email,
                            tokens: 0,
                            date: currentDate.toString(),
                            free:false
                        };



                    }
                    else {

                      if(data[index].tokens >= 1)
                      {
                        let tokensLeft = data[index].tokens - 1;
                        window.localStorage.setItem("tokens",tokensLeft);
                        updatedRecord = {
                            email: data[index].email,
                            tokens: data[index].tokens - 1,
                            date: data[index].date,
free:true
                        };
                      }
                      else{
                        let tokensLeft = 0;
                        window.localStorage.setItem("tokens","Unlimited");
                        updatedRecord = {
                            email: data[index].email,
                            tokens: 0,
                            date: data[index].date,
free:false
                        };
                      }

                    }
                    // Update the specific record in the array
                    data[index] = updatedRecord;

                    // Update the array in the document
                    collectionRef.update({
                        records: data
                    })
                        .then(function () {
                            resolve(true);
                        })
                        .catch(function (error) {
                            reject(false);
                        });
                }
                


            }
        });


    });
}







function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return jsonPayload;
}










///get selected text on the body

//get body

export function getSelectedBody() {

  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.getSelectedDataAsync(Office.CoercionType.Text, function(result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        let emailBody = result.value;
        resolve(emailBody);
      } else {
        const error = result.error.message;
        reject(error);
      }
    });
  });
}

///insert to body

export function insertToBody(selectedLink,qrCodeLink) {

  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
   
        // let parser = new DOMParser();
        // let emailBody = parser.parseFromString(asyncResult.value.trim(), 'text/html');
      //  let emailBody = asyncResult.value;
      //         emailBody='<html><body>'+emailBody+qrCodeLink+'</body></html';
      var htmlBody = asyncResult.value;

 Office.context.mailbox.item.addFileAttachmentFromBase64Async(
  qrCodeLink,
        'QrCode.png',
        { isInline: true }, // Set isInline to true to make it an inline attachment
        function (attachmentResult) {
          if (attachmentResult.status === Office.AsyncResultStatus.Succeeded) {
            // Get the content ID of the attachment
         


       // Add the image to the email body using the content ID
       htmlBody += '<img src="cid:QrCode.png" alt="Embedded Image">';
   

                Office.context.mailbox.item.body.setAsync(htmlBody, { coercionType: Office.MailboxEnums.BodyType.Html }, function (result) {
                  if (result.status === Office.AsyncResultStatus.Succeeded) {
                    console.log("Email body with image set successfully.");
                    updateDb("Insert",1)

                   // resolve(true);
                  } else {
                    console.error("Error setting email body: " + result.error.message);
                    reject(false);
                  }
                });

              
            

     

            // Set the email body
         
          } else {
            console.error("Error adding image attachment: " + attachmentResult.error.message);
          }
        }
      );
    }




// Office.context.mailbox.item.body.setSelectedDataAsync(emailBody, { coercionType: Office.CoercionType.Html }, function (result) {
//   if (result.status === Office.AsyncResultStatus.Succeeded) {
//     console.log("HTML content inserted successfully.");
//     resolve(true);

//   } else {
//     console.error("Error inserting HTML content: " + result.error.message);
//     resolve(false);
//   }
// });


       
       else {
        const error = result.error.message;
        reject(error);
      }
    });
  });
}

// Office.context.mailbox.item.body.setAsync(
//   wholeSrtring,
//   {
//       coercionType: Office.CoercionType.Html


//   },
// );


