import * as React from "react";






export default class NoSubscription extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
       
       result:props.result
      
       
      };
     
    
    }
 
   
     
  
   
    
  
     
  
  
  
  
  
  
  
    render() {
    
    
    
      return (
 
<div className="row   vh-100 d-flex align-items-center justify-content-center">
  <div className="col-10 col-md-6">
   {this.state.result}
 
 
 <button className="btn btn-primary">

Upgrade
 </button>
  </div>
</div>
      
      )
  
  
   
  
  
  
  
  
  
  
  }
  }


