import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { login,checkSubscription,pay ,NotionSignIn,getAllDatabases,getEmailBody,createPage,getSelectedBody,insertToBody} from "./Services";
import {NoupdateDb,Subscription} from "./NoSubscription";
import QRCode from 'qrcode';
// import CircularProgress from '@mui/material/CircularProgress';










export default class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
     login:false,
     noSubscription:false,
     approve:false,
     result:"",
     paymentPlans:false,
     showTokens:false,
     makePayments:false,
     dbErrpr:false,
     tokens:0,
     paymentUrl:"",
     paymentError:false,
     loader:false,
     clientId:"",
     clientSecret:"",
     notionDatabases:[],
     databaseId:"",
     notionLoggedIn:false,
     body:"",
     tokens:"",
     qrCode:"",
     selectedLink:"",
     QrImgTag:"",
     profile:false,
     collapse:false
    
    };
    // this.saveToNotion = this.saveToNotion.bind(this); // Bind the function

  
  }
  componentDidMount() {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    

 this.checkLogin();

  }

  
  async checkLogin() {
    let login = true;
    this.setState({loader:true})
    let loggedIn = window.localStorage.getItem("loggedIn");
    if ((loggedIn !== null && loggedIn !== 'false') || loggedIn === 'true'  ) {
      login = true;
      this.setState({ login: true });
    } else {
      login = false;
      this.setState({ login: false });
    }
    this.setState({loader:true});
    if (login) {
     
      let result = await this.checkSub();
  
      if (result === true) {
    
        this.setState({
          noSubscription:false,
          loader:false,
          approve:false,
          result:"",
          tokens:window.localStorage.getItem("tokens"),
          paymentPlans:false,
          showTokens:true,
          makePayments:false,
          paymentUrl:"",
          paymentError:false,
          login:true,
qrCodeScreen:true
        });
      } else {
        this.setState({
          login: true,
          loader:false,
     approve:false,
   
     paymentPlans:false,
 
     showTokens:false,
          makePayments:false,
          tokens:window.localStorage.getItem("tokens"),
     paymentUrl:"",

   
qrCodeScreen:false,
     paymentError:false,
   noSubscription: true,
    result:
            "No subscription Found! You have not subscribed QR Code for outlook  and your API calls have all been used. Please subscribe on the Side Menu. For assistance, contact us on smallpdfkenya@gmail.com",
        });
      }
    } else {
      this.setState({
        noSubscription:false,
        approve:false,
        result:"",
        loader:false,
        paymentPlans:false,
        showTokens:false,
        makePayments:false,
        tokens:window.localStorage.getItem("tokens"),
        paymentUrl:"",
        paymentError:false,
  
        login:false,
        qrCodeScreen:false,
      
        

      
      });
    }
  }
  
 
  signIn = (e) => {
    this.setState({loader:true})
    login()
      .then(async (res) => {
        console.log(res);
        window.localStorage.setItem("loggedIn", true);
        let result = await this.checkSub();
  
        if (result == true) {
      
          this.setState({
            login:true,
    noSubscription:false,
    approve:false,
    tokens:window.localStorage.getItem("tokens"),
    qrCodeScreen:true,
    paymentPlans:false,
    paymentError:false,
    showTokens:true,
    makePayments:false,
    loader:false
          });
        } else {
          this.setState({
            login:true,
            noSubscription:true,
            approve:false,
            loader:false,
           qrCodeScreen:false,
            paymentPlans:false,
            paymentError:false,
            showTokens:true,
            makePayments:false,
            result:
              "No subscription Found! You have not subscribed QR COde for outlook and your API calls have all been used. Please subscribe on the Side Menu. For assistance, contact us on smallpdfkenya@gmail.com",
              showTokens:true
            });
        }
      });
  }
  
  async checkSub() {
    let sub = checkSubscription(1);
  
    return sub.then(function (param) {
      return param;
    });
  }
  

upgrade=e=>{


  this.setState({paymentPlans:true,noSubscription:false,login:true,qrCodeScreen:false})
}

gotoPayment=(e,tokens)=>{

console.log(tokens);
this.setState({
  paymentUrl:e,
  tokens:window.localStorage.getItem("tokens"),
  login:true,
paymentPlans:false,
paymentError:false,
noSubscription: false,
makePayments: true,
showTokens:false


})

}
makePayments=e=>{
  this.setState({loader:true});
pay(this.state.paymentUrl,this.state.tokens).then(async res=>{

console.log("payment"+res);

if(res){
this.setState({

  login:true,
  loder:false,
  noSubscription:false,
  approve:false,
 
  paymentPlans:false,
  paymentError:false,
  showTokens:true,
  tokens:window.localStorage.getItem("tokens"),
  qrCodeScreen:true,
  makePayments:false,


})

}
else{
  this.setState({

    login:true,
    noSubscription:false,
    approve:false,
   loader:false,
    paymentPlans:false,
    paymentError:true,
    qrCodeScreen:false,
    showTokens:false,
    makePayments:false,
    result : "Payment failed try again"
  
  
  })

}
})
  
}









generateQrCode=()=>{

getSelectedBody().then(res=>{
if(res.data !==""){
  this.setState({selectedLink:res.data});
  QRCode.toDataURL(res.data, (err, url) => {
    if (err) {
      console.error(err);
      return;
    }
    let img='<img src"'+url+'"/>';
    let newUrl=url.split('base64,')
    this.setState({ qrCode: url,QrImgTag:newUrl[1]});
  });


}
else{

}
  
})

}

 insertInBody=async ()=>{
this.setState({loader:true})
      let result = await this.checkSub();
      if(result===true){
insertToBody(this.state.selectedLink,this.state.QrImgTag).then(res=>{

  this.setState({loader:false,tokens:window.localStorage.getItem("token")})


console.log(res);

})
      }
else{
  this.setState({
    login: true,
    loader:false,
    qrCodeScreen:false,
approve:false,

paymentPlans:false,

showTokens:true,
    makePayments:false,
    tokens:window.localStorage.getItem("tokens"),
    paymentUrl:"",
notionLogin:false,
login:true,

paymentError:false,
noSubscription: true,
result:
      "No subscription Found! You have not subscribed Notion and your API calls have all been used. Please subscribe on the Side Menu. For assistance, contact us on smallpdfkenya@gmail.com",
  });
}


logOut=()=>{
  let profile=this.state.profile;
  profile=!profile;
this.setState({profile:profile});
}
}

  render() {
  
  
  
    return (



 <>
  {/* {this.state.loader && 
 <CircularProgress /> } */}
 {this.state.login &&
<nav class="navbar  justify-content-between" style={{padding:"0px 7px 0px 7px",backgroundColor:'#0f6cbd',color:'white'}}>
  <a class="navbar-brand" style={{color:'white'}}>QR Code for Outlook</a>
  <form class="form-inline">
   
    <a class="btn btn-sm my-2 my-sm-0" style={{color:'white'}} onClick={e=>this.setState({profile:!this.state.profile})}  ><i class="fa fa-regular fa-user"></i></a>
  </form>
</nav>
  }
{this.state.profile &&
    <div className=" row p-1 justify-content-between" style={{marginBottom:'-25px',marginRight:'0px',}}>
<div className="col-8">
<p><b>{window.localStorage.getItem('userName')}<br/>
  { window.localStorage.getItem('userEmail')}</b></p>
  </div>
  <div className="col-3" style={{textAlign:'right'}}>
  <a
  class="btn btn-sm "
  onClick={() => {
    this.setState({
      login: false,
      makePayments: false,
      noSubscription: false,
      approve: false,
      showTokens: false,
      qrCodeScreen: false,
      paymentError: false,
      paymentPlans: false,profile:false
    });
    window.localStorage.removeItem("userEmail");
    window.localStorage.setItem("loggedIn",false);
  }}
  title="Log Out"
>




  <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 512 512"><path d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg>
  </a>

  </div>
  <div>
  

  
 
  </div>
</div>
  }


<div className="container">


     {this.state.showTokens && 
<div className="row">

<div className="col-8">  
  
   <p className="mt-3" style={{fontSize:"13px",backgroundColor:'#0f6cbd',padding:'5px',borderRadius:'10px',color:'white',textAlign:'center'}}>{this.state.tokens} units remaining</p>
   
   </div>
   <div className="col-4">  
  
   <button className="btn btn-sm btn-primary mt-3 w-100" onClick={this.upgrade}>
      Upgrade
    </button>
   
   </div>
  </div>} 
  
  {!this.state.login &&
<div className="row   vh-100 d-flex align-items-center justify-content-center">

  <div className="col-10 col-md-6">
  <di className="card" style={{padding:'18px 18px 10px 18px',marginBottom:'35px'}}>
    <p>
  Easily Generate <strong>QR Code</strong> from your links
such as <strong>meeting link and URLs</strong> for sharing.
This will allow people to easily scan on their phone.
Please <strong>sign in</strong> to access the functional addin.</p>
  </di>
    <a href="#" className="btn btn-primary form-control-sm w-100" id="signinMicrosoft_button" onClick={this.signIn}>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" style={{ float: 'left' }}>
        <title>MS-SymbolLockup</title>
        <rect x="1" y="1" width="9" height="9" fill="#f25022"></rect>
        <rect x="1" y="11" width="9" height="9" fill="#00a4ef"></rect>
        <rect x="11" y="1" width="9" height="9" fill="#7fba00"></rect>
        <rect x="11" y="11" width="9" height="9" fill="#ffb900"></rect>
      </svg>
      Sign In with Microsoft
    </a>
  </div>
</div>





 }  
    {this.state.noSubscription &&
   <>
<div className="row vh-100 d-flex align-items-center justify-content-center">
  <div className="col-10 col-md-6">
    {this.state.result}
    <br />
    <button className="btn btn-primary mt-3 w-100" onClick={this.upgrade}>
      Upgrade
    </button>
  </div>
</div>








 </> 
   }  


{this.state.qrCodeScreen &&
<>
<div className="card" style={{fontSize:"12px"}}>
<div class="card-body">
  <div className="row">
<div className="col-10">
<h6 class="card-title">How to use QR</h6>
  </div>
  <div className="col-2">
  {!this.state.collapse &&
<a onClick={e=>this.setState({collapse:true})}><i class="fa fa-solid fa-chevron-down"></i></a>
  }
    {this.state.collapse &&
<a onClick={e=>this.setState({collapse:false})}><i class="fa fa-solid fa-chevron-up"></i></a>
  }
</div>
</div>
{this.state.collapse &&
<ol>
  <li>
Ensure you are on Email compose mode
  </li>
  <li>
    Highlight the link you want to generate QR code
    </li>
    <li>
      For teams meeting link, Edit to get hyperlink and highlight the Hyperlink
    </li>
    <li>
      Click on Generate QR code button
    </li>
    <li>
      To insert QR code inside email click on Insert QR code button
    </li>
    <li>Ensure to upgrade for unlimited tool utitlization</li>
  </ol>
}
  </div>
  </div>
<div className="row  align-items-center justify-content-center">


  <div className="col-10 col-md-6">
  
    <button className="btn btn-primary mt-3 ms-1 w-100"  onClick={this.generateQrCode}>
      Generate QR Code
    </button>
    <br/>
    <br/>
    {this.state.qrCode ?
  <>
  <div className="text-center"> {/* Added 'mx-auto' class here */}
    <img src={this.state.qrCode} />
  </div>
  <button className="btn btn-primary mt-3 ms-1 w-100" onClick={this.insertInBody}>
    Insert QR Code
  </button>
</>

:
<h4>Please select a link</h4>
  }
  </div>





</div>

</>  
  }

{this.state.paymentPlans && 
   <>
<div className="row vh-100 d-flex align-items-center justify-content-center" style={{marginTop:'-8vh'}}>

  <div className="col-10 col-md-6">
  <div className="card row" style={{height:'75px'}}>
  <div className="card-body d-flex justify-content-between align-items-center">
    <div>
      <h6 className="card-subtitle mb-2 text-muted" style={{fontSize:'12px'}}>Free</h6>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>5 Tokens</h6>
   
    </div>
   
  </div>
</div>

  <div className="card row" style={{height:'75px'}}>
  <div className="card-body d-flex justify-content-between align-items-center">
    <div>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>$4/month</h6>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>Unlimited QR Codes</h6>
    </div>
    <button className="btn btn-sm btn-primary" onClick={() => this.gotoPayment('https://buy.stripe.com/dR69E4bM70qZ1uU28d', 0)}>
  Start
</button>
  </div>
</div>


 {/* <div className="card row" style={{height:'75px'}}>
  <div className="card-body d-flex justify-content-between align-items-center">
    <div>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>Card subtitle</h6>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>Card subtitle</h6>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>Card subtitle</h6>
    </div>
    <button className="btn btn-sm btn-primary" onClick={this.gotoPayment}>Start</button>
  </div>
</div>
<div className="card row" style={{height:'75px'}}>
  <div className="card-body d-flex justify-content-between align-items-center">
    <div>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>Card subtitle</h6>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>Card subtitle</h6>
      <h6 className="card-subtitle mb-2 text-muted"  style={{fontSize:'12px'}}>Card subtitle</h6>
    </div>
    <button className="btn btn-sm btn-primary" onClick={this.gotoPayment}>Start</button>
  </div>
</div>  */}

 <p>Need more token?Email us at <b>smallpdfkenya@gmail.com</b> for custom plans.</p>

      </div>
    
    </div>









 </> 
    } 


{this.state.makePayments &&



<div className="row vh-100 d-flex align-items-center justify-content-center">
  <div className="col-10 col-md-6">
  Please use <b>{window.localStorage.getItem("userEmail")}</b> for payment otherwise you will not be able to continue
    <br />
    <button className="btn btn-primary mt-3 w-100" onClick={this.makePayments}>
      Agree
    </button>
  </div>
</div>

  }

{this.state.paymentError &&



<div className="row vh-100 d-flex align-items-center justify-content-center">
  <div className="col-10 col-md-6">
{this.state.result}
    <br />
    <button className="btn btn-primary mt-3 w-100" onClick={this.upgrade}>
      Upgrade
    </button>
  </div>
</div>

  } 
 

</div>
 </>
    
    )


 







}
}
